import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_banner = _resolveComponent("banner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.hasRestrictionError)
      ? (_openBlock(), _createBlock(_component_banner, {
          key: 0,
          class: "mb-2",
          type: _ctx.BannerTypes.Error,
          header: "Your account has a restriction",
          summary: "You have a restriction on your account that must be resolved to continue with your student financial aid. Please view the message and resolve the items to minimize disruption and impact."
        }, {
          actions: _withCtx(() => [
            _createVNode(_component_v_btn, {
              color: "error",
              onClick: _ctx.viewStudentAccountActivity
            }, {
              default: _withCtx(() => [
                _createTextVNode(" View activity ")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }, 8, ["type"]))
      : _createCommentVNode("", true),
    (_ctx.hasRestrictionWarning)
      ? (_openBlock(), _createBlock(_component_banner, {
          key: 1,
          class: "mb-2",
          type: _ctx.BannerTypes.Warning,
          header: "Warning",
          summary: "Check your account activity page for important updates regarding your account."
        }, {
          actions: _withCtx(() => [
            _createVNode(_component_v_btn, {
              color: "warning",
              onClick: _ctx.viewStudentAccountActivity
            }, {
              default: _withCtx(() => [
                _createTextVNode(" View account activity ")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }, 8, ["type"]))
      : _createCommentVNode("", true)
  ], 64))
}