import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["colspan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_check_permission_role = _resolveComponent("check-permission-role")!
  const _component_body_header = _resolveComponent("body-header")!
  const _component_status_chip_supplier = _resolveComponent("status-chip-supplier")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_toggle_content = _resolveComponent("toggle-content")!
  const _component_content_group = _resolveComponent("content-group")!
  const _component_edit_c_a_s_supplier_info_modal = _resolveComponent("edit-c-a-s-supplier-info-modal")!
  const _component_body_header_container = _resolveComponent("body-header-container")!
  const _component_tab_container = _resolveComponent("tab-container")!

  return (_openBlock(), _createBlock(_component_tab_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_body_header_container, null, {
        header: _withCtx(() => [
          _createVNode(_component_body_header, {
            title: "CAS Supplier Information",
            subTitle: "The top row in the table below is the most current CAS information for the student."
          }, {
            actions: _withCtx(() => [
              _createVNode(_component_check_permission_role, {
                role: _ctx.Role.AESTEditCASSupplierInfo
              }, {
                default: _withCtx(({ notAllowed }) => [
                  _createVNode(_component_v_btn, {
                    class: "float-right",
                    color: "primary",
                    "data-cy": "editCASSupplierInfoButton",
                    disabled: notAllowed,
                    onClick: _ctx.addCASSupplierInfo,
                    "prepend-icon": "fa:fa fa-plus-circle"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Edit info")
                    ]),
                    _: 2
                  }, 1032, ["disabled", "onClick"]),
                  _createVNode(_component_v_btn, {
                    class: "mr-2 float-right",
                    color: "primary",
                    "data-cy": "retryCASSupplierButton",
                    disabled: notAllowed || _ctx.retryButtonDisabled,
                    onClick: _ctx.retryCASSupplier,
                    "prepend-icon": "fa:fa fa-repeat",
                    loading: _ctx.retryLoading
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Retry")
                    ]),
                    _: 2
                  }, 1032, ["disabled", "onClick", "loading"])
                ]),
                _: 1
              }, 8, ["role"])
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_content_group, null, {
            default: _withCtx(() => [
              _createVNode(_component_toggle_content, {
                toggled: !_ctx.casSupplierInfo?.items?.length
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_data_table, {
                    headers: _ctx.CASSupplierInformationHeaders,
                    items: _ctx.casSupplierInfo.items,
                    "items-per-page": _ctx.DEFAULT_PAGE_LIMIT,
                    "items-per-page-options": _ctx.ITEMS_PER_PAGE,
                    "show-expand": ""
                  }, {
                    [`item.dateCreated`]: _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.dateOnlyLongString(item.dateCreated)), 1)
                    ]),
                    [`item.status`]: _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(item.status), 1)
                    ]),
                    [`item.supplierNumber`]: _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(item.supplierNumber), 1)
                    ]),
                    [`item.supplierProtected`]: _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.booleanToYesNo(item.supplierProtected)), 1)
                    ]),
                    [`item.supplierStatus`]: _withCtx(({ item }) => [
                      _createVNode(_component_status_chip_supplier, {
                        status: item.supplierStatus
                      }, null, 8, ["status"])
                    ]),
                    [`item.isValid`]: _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.booleanToYesNo(item.isValid)), 1)
                    ]),
                    [`item.supplierSiteCode`]: _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(item.supplierSiteCode), 1)
                    ]),
                    [`item.addressLine1`]: _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.emptyStringFiller(item.addressLine1)), 1)
                    ]),
                    [`item.siteStatus`]: _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.emptyStringFiller(item.siteStatus)), 1)
                    ]),
                    [`item.siteProtected`]: _withCtx(({ item }) => [
                      _createTextVNode(_toDisplayString(_ctx.emptyStringFiller(item.siteProtected)), 1)
                    ]),
                    "expanded-row": _withCtx(({ item, columns }) => [
                      _createElementVNode("tr", null, [
                        _createElementVNode("td", {
                          colspan: columns.length
                        }, [
                          _createElementVNode("ul", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.errors, (error, index) => {
                              return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(error), 1))
                            }), 128))
                          ])
                        ], 8, _hoisted_1)
                      ])
                    ]),
                    [`item.data-table-expand`]: _withCtx(({
                item,
                internalItem,
                isExpanded,
                toggleExpand,
              }) => [
                      (item.errors && item.errors.length > 0)
                        ? (_openBlock(), _createBlock(_component_v_btn, {
                            key: 0,
                            icon: 
                  isExpanded(internalItem)
                    ? '$expanderCollapseIcon'
                    : '$expanderExpandIcon'
                ,
                            variant: "text",
                            onClick: ($event: any) => (toggleExpand(internalItem))
                          }, null, 8, ["icon", "onClick"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["headers", "items", "items-per-page", "items-per-page-options"])
                ]),
                _: 1
              }, 8, ["toggled"])
            ]),
            _: 1
          }),
          _createVNode(_component_edit_c_a_s_supplier_info_modal, {
            ref: "addCASSupplierModal",
            allowedRole: _ctx.Role.AESTEditCASSupplierInfo
          }, null, 8, ["allowedRole"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}