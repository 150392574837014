import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_formio_container = _resolveComponent("formio-container")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_formio_container, {
        formName: "supportingusersdashboard",
        onCustomEvent: _ctx.customEventCallback,
        formData: _ctx.formData
      }, null, 8, ["onCustomEvent", "formData"])
    ]),
    _: 1
  }))
}